<template>
  <v-container fluid>
    <v-spacer />
    <v-row>
      <v-col cols="12">
        <v-select
          v-model="search_brands"
          :items="brands"
          label="Brand"
          outlined
          multiple
          clearable
          item-text="name"
          item-value="brand_id"
          hide-details
        ></v-select>
      </v-col>
      <v-col cols="12">
        <!-- Export Products Button -->
        <v-btn elevation="2" block @click="exportProducts(search_brands, [])">Export Products</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { brandsCore } from "@/mixins/brandsMixin.js";
  import exportsMixin from "@/mixins/exportsMixin.js";
  export default {
    mixins: [brandsCore, exportsMixin],
    data() {
      return {
        search_brands: [],
      };
    },
  };
</script>
