<template>
  <v-window style="overflow: visible;">
    <v-row class="ma-0 mb-1">
      <v-col cols="auto" class="my-auto pa-0 mr-4">
        <v-btn v-if="back" elevation=4 class="rounded-lg" @click="$router.go(-1)">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col id="title-column" ref="title-column" class="text-truncate my-auto pa-0">
        <span v-if="!products_page" id="title-text" ref="title-text" class="font-weight-bold mr-2" style="font-size: 2em">{{ title }}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on }">
            <span v-on="on" v-if="products_page" class="font-weight-bold mr-2" style="font-size: 2em">{{ title }}</span>
          </template>
          <span>{{ title }}</span>
        </v-tooltip>
      </v-col>
      <v-col cols=auto class="my-auto ml-auto pa-0">
        <v-tooltip bottom v-for="(option, i) in options" :key="i" v-show="false">
          <template v-slot:activator="{ on }" v-if="!option.hidden">
            <span v-on="showTooltip(option) ? on : null">
              <v-btn
                :color="option.color"
                :disabled="option.disabled"
                @click="option.action"
                class="mx-2 rounded-lg"
                elevation=4
              >
                <v-icon dark>{{option.icon}}</v-icon>
              </v-btn>
            </span>
          </template>
          <span>{{option.disabled ? option.title_disabled : option.title}}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row class="ma-0">
      <v-col cols=auto class="mt-n2 pa-0" style="min-width: 255px" v-if="subtitle !== undefined">
        <span class="font-weight-thin" style="font-size: 0.85em">{{ subtitle }}</span>
      </v-col>
      <v-divider v-if="divider" class="mt-2 mb-5"/>
    </v-row>
  </v-window>
</template>

<script>
export default {
  name: "PageHeader",
  props: {
    title: String,
    subtitle: String,
    options: Array,
    divider: {
      default: true,
      type: Boolean
    },
    back: {
      default: true,
      type: Boolean
    }
  },
  methods: {
    showTooltip(option) {
      return (option.disabled && option.title_disabled !== undefined) || (!option.disabled && option.title !== undefined);
    }
  },
  computed: {
    products_page() {
      if (this.$route.name === 'Product') return true;
      return false;
    }
  }
};
</script>