<template>
  <v-window style="overflow: visible;">
    <v-btn-toggle v-model="current_tab" mandatory style="justify-content: space-evenly;" class="background py-4" active-class="current-tab">
      <v-row>
        <v-col v-for="(tab, i) in tabs" :key="i">
          <v-btn elevation=4 class="button-style rounded-lg" :value="tab">{{ tab }}</v-btn>
        </v-col>
      </v-row>
    </v-btn-toggle>
    <v-divider class="mt-1 pb-5" />

    <div v-if="current_tab === 'Import'">
      <Import>
      </Import>
    </div>
    <div v-if="current_tab === 'Export'">
      <Export>
      </Export>
    </div>
  </v-window>
</template>

<script>
import Import from "@/components/import-export/Import.vue";
import Export from "@/components/import-export/Export.vue";
export default {
  data() {
    return {
      current_tab: 'Import',
      tabs: ['Import', 'Export'],
    };
  },
  components: {
    Import,
    Export,
  }
}
</script>