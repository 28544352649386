<template>
  <v-container fluid>
    <Header 
      title="Import/Export Products"
      :divider="false"
      :options="[]"
    ></Header>

    <!-- Import/Export Data Tabs -->
    <ImportExportTabs
    ></ImportExportTabs>
  </v-container>
</template>

<script>
import Header from "@/components/Header.vue"
import ImportExportTabs from "@/components/import-export/ImportExportTabs.vue";
export default {
  components: {
    Header,
    ImportExportTabs
  }
};
</script>