export default {
  methods: {
    exportProducts(search_brands, search_products) {
      let payload = { brands: search_brands, products: search_products };
      this.$axios
        .post(`${process.env.VUE_APP_API_BASE_URL}/products/export`, payload, { responseType: "blob" })
        .then(result => {
          const url = window.URL.createObjectURL(
            new Blob([result.data], {
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            }),
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `Products Export.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(err => {
          console.log(err);
        });
    },
  },
};
