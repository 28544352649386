<template>
  <v-container fluid>
    <v-btn-toggle v-model="import_type" mandatory>
      <v-btn value="Create">Create</v-btn>
      <v-btn value="Update">Update</v-btn>
    </v-btn-toggle>
    <h3>Available Import Fields</h3>
    <p v-html="fields"></p>
    <a @click="downloadImportFile()">Sample {{ import_type }} File</a>
    <br />
    <v-card>
      <v-form v-if="access.create_access">
        <v-container>
          <v-row justify="center">
            <v-card-title class="headline">{{ import_type }} File</v-card-title>
          </v-row>
          <VueFileAgent
            v-model="importFile"
            :multiple="false"
            accept=".csv,.xlsx"
            :deleteable="true"
            :sortable="false"
          ></VueFileAgent>
          <v-row justify="center">
            <v-card-actions>
              <v-spacer></v-spacer>
                <v-tooltip bottom :disabled="!skuLimitMet">
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      <v-btn
                        color="blue darken-1"
                        text
                        :disabled="importFile === null || skuLimitMet"
                        @click="upload()"
                        >Upload</v-btn
                      >
                    </div>
                  </template>
                  <span>You have reached your sku limit.</span>
                </v-tooltip>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-form>
      <v-card-title class="justify-center" v-else>You do not have permission to import products!</v-card-title>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      access: this.$store.getters.getAccessLevel['products'],
      importFile: null,
      import_type: null,
    }
  }, 
  computed: {
    skuLimitMet() {
      return this.$store.getters.getOrganizationSettings.sku_limit <= this.$store.getters.getOrganizationState.sku_count;
    },
    fields() {
      if (this.import_type === 'Create') {
        return "<b>product_name</b>, html_description, <b>brand</b>, variant_type (<b>if is_variant</b>), <b>is_variant</b>, variant_name (<b>if is_variant</b>), <b>sku</b>, barcode, image_url, variant_image_sku <br/>Fields in <b>bold</b> are required."
      } else {
        return "product_id (<b>if updating product</b>), product_name, html_description, brand, variant_type, variant_id (<b>if updating variant</b>), variant_name, sku, barcode <br/>Fields in <b>bold</b> are required."
      }
    },
    sampleImportFields() {
      if (this.import_type === 'Create') {
        return [
        [
            "product_name",
            "html_description",
            "brand",
            "variant_type",
            "is_variant",
            "variant_name",
            "sku",
            "barcode",
            "image_url",
            "variant_image_sku",
        ],
        [
            "Sample Product Import 1",
            "<h1>SAMPLE PRODUCT</h1>",
            "Sample Brand",
            "",
            "0",
            "",
            "sample-product-1",
            "123456",
            "https://res.cloudinary.com/breezy-software/image/upload/v1651708581/assets/NL47-6005TW-15PK_2_1648621146_500x_yo8e0p.webp",
            "",
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "https://res.cloudinary.com/breezy-software/image/upload/v1651708802/assets/NL47-6005HX-15PK_3_1628461329_500x_yluzlc.webp",
            "",
        ],
        [
            "Sample Variant Import 1",
            "<h1>SAMPLE VARIANT</h1>",
            "Sample Brand",
            "Color",
            "1",
            "Orange",
            "sample-variant-1",
            "12345",
            "https://res.cloudinary.com/breezy-software/image/upload/v1651708692/assets/NL42-0005HX-9PK_02_1636432838_500x_rtx5ka.webp",
            "sample-variant-1",
        ],
        [
            "",
            "",
            "",
            "",
            "1",
            "Blue",
            "sample-variant-2",
            "23456",
            "https://res.cloudinary.com/breezy-software/image/upload/v1651708613/assets/NL42-0005HX-9PK_01_1648620877_6c36afbc-3081-4c46-9b10-39155a160cff_500x_vmuddq.webp",
            "sample-variant-2",
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "https://res.cloudinary.com/breezy-software/image/upload/v1651708863/assets/Shapes_Hex_15x_Music_Room_4000x2250_1629271008_500x_go9bul.webp",
            "",
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "https://res.cloudinary.com/breezy-software/image/upload/v1651708864/assets/Shapes_Hex_15x_Music_Room_4000x4000_1629271008_500x_y81zvt.webp",
            "sample-variant-2",
        ],
        [
            "Sample Product Import 2",
            "<h1>SAMPLE PRODUCT</h1>",
            "Sample Brand 2",
            "",
            "0",
            "",
            "sample-product-2",
            "345678",
            "https://res.cloudinary.com/breezy-software/image/upload/v1651708935/assets/NL45-0800WT240B22-3PK_1_1648619422_f8405e34-dab9-477a-a05f-1232f034fb75_500x_tbrccz.webp",
            "",
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "https://res.cloudinary.com/breezy-software/image/upload/v1651708935/assets/Essentials_Smart_Bulb_x3_Pack_Lifestyle_1632692324_500x_vypej7.webp",
            "",
        ],
        ]
      } else {
        return [
        [
            "product_id",
            "product_name",
            "html_description",
            "brand",
            "variant_type",
            "variant_id",
            "variant_name",
            "sku",
            "barcode"
        ],
        [
            "-1",
            "Sample Update Product 1",
            "<h1>SAMPLE UPDATE PRODUCT</h1>",
            "Sample Update Brand",
            "",
            "-1",
            "",
            "sample-update-product-1",
            "654321",
        ],
        [
            "-2",
            "Sample Update Variant 1",
            "<h1>SAMPLE UPDATE VARIANT</h1>",
            "Sample Update Brand",
            "Size",
            "-2",
            "Small",
            "sample-update-variant-1",
            "54321",
        ],
        [
            "",
            "",
            "",
            "",
            "",
            "-3",
            "Large",
            "sample-update-variant-2",
            "65432"
        ]
        ]
      }
    },
  },
  methods: {
    downloadImportFile() {
      let csvContent = this.sampleImportFields
        .map((e) => e.join(","))
        .join("\n");
      const url = window.URL.createObjectURL(
        new Blob([csvContent], {
          type: "text/csv;charset=utf-8;",
        })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${this.import_type} Products Template.csv`);
      document.body.appendChild(link);
      link.click();
    },
    async upload() {
      let payload = new FormData();
      payload.append("import_file", this.importFile.file);
      this.$axios.post(`${process.env.VUE_APP_API_BASE_URL}/products/${this.import_type === 'Create' ? 'uploadCSV' : 'update'}`, payload)
        .then(() => {
          document.getElementById('notificationsBell').click();
          this.importFile = null;
          this.$store.dispatch("showSnackbar", {text: `${this.import_type === 'Create' ? 'Importing' : 'Updating'} products in the background!`, color: "success", timeout: 2500});
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
}
</script>